<template>
  <div>
    <div v-if="!paymentStore.isSucceeded" class="px-x5">
      <img
        src="~/assets/img/payment-processing.svg"
        alt="Payment Processing"
        class="w-[88px] rounded-x1 h-[88px] min-w-[88px] mx-auto object-cover mb-5 mt-x6"
      />
      <p class="font-bold text-center w-full mb-5">Payment Processing</p>
      <div
        class="text-font-primary w-full text-center bg-light-background py-5 font-bold text-h4"
      >
        {{ useFormatPrice(paymentStore.finalCardAmount) }}
      </div>
    </div>

    <!-- SUCCESSFUL PAYMENT -->
    <div class="px-x5" v-else-if="false">
      <div class="flex flex-col items-center justify-center gap-x3 pt-[45px]">
        <img src="~/assets/img/confirmed.svg" alt="" />

        <p class="font-bold text-h5 leading-base-md text-black mb-x5">
          Payment Successful!
        </p>
      </div>

      <!-- SPLIT PAYMENT -->
      <template v-if="paymentStore.remaining">
        <div class="flex justify-between pt-x5">
          <p class="text-font-primary text-sm leading-base">Cash:</p>

          <p class="text-font-primary font-semibold text-sm leading-base">
            {{ useFormatPrice(paymentStore.customerPaying) }}
          </p>
        </div>

        <div class="flex justify-between pt-x5 mb-x5">
          <p class="text-font-primary text-sm leading-base">Card Payment:</p>

          <p class="text-font-primary font-semibold text-sm leading-base">
            {{ useFormatPrice(paymentStore.finalCardAmount) }}
          </p>
        </div>

        <div
          class="text-font-primary w-full text-center bg-light-background py-5 font-bold text-h4"
        >
          {{ useFormatPrice(paymentStore.totalValue) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePaymentStore } from "~/store/payment";
// import { ToastType } from "~/types/general";

const { $toast } = useNuxtApp();

const paymentStore = usePaymentStore();

// checking live status
// watch(
//   () => paymentStore.paymentEvent,
//   (value) => {
//     if (value) {
//       switch (value.status) {
//         case "paid":
//           paymentStore.isSucceeded = true;

//           // paymentStore.pusher.unsubscribe(paymentStore.channelName);
//           break;
//         case "declined":
//           paymentStore.isSucceeded = false;

//           $toast("The payment is declined", {
//             type: ToastType.ERROR,
//             duration: 3000,
//           });
//       }
//     }
//   }
// );
</script>
